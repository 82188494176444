.MuiTextField-root {
  input.MuiInputBase-input {
    font-size: 18px;
    padding: 12px 16px;
    border-radius: 12px;
    background-color: #f4f4f4;
  }
}

.MuiTableContainer-root {
  margin: 24px 0px;

  .MuiTableRow-root:hover {
    background-color: #00000006;
  }
  .MuiTableCell-head {
    padding: 12px 12px;
    font-weight: 700;
  }
  .MuiTableCell-body {
    padding: 12px 12px;
  }
}

.MuiToolbar-root {
  padding: 0 32px !important;
}

.MuiListItem-root {
  &:hover {
    background-color: #fde3e3;
  }
}

.MuiListItemIcon-root {
  min-width: auto !important;
}

.MuiListItemText-primary {
  font-weight: 700 !important;
}

.MuiDialog-root {
  .MuiDialogActions-root {
    padding: 16px 12px;
  }
}

.MuiButton-root.MuiButton-text,
.MuiButton-root.MuiButton-contained {
  border-radius: 12px !important;
  font-size: 18px;
  padding: 12px 24px;
  @media screen and (max-width: 640px) {
    padding: 8px 16px;
  }
  &:hover {
    filter: brightness(0.9);
  }
  &.MuiButton-textInherit {
    &:hover {
      background-color: #fffd;
    }
  }
  > .MuiLoadingButton-loadingIndicator {
    color: #333;
  }
  &.Mui-disabled {
    background-color: #656565 !important;
  }
}

.MuiDialog-root {
  .MuiDialogTitle-root {
    padding: 12px 16px;
    font-size: 20px;
    font-weight: 700;
  }
  .MuiDialogContent-root {
    padding: 16px;
  }
  .MuiDialogActions-root {
    padding: 12px 16px;
  }
}

.MuiLoadingButton-root {
  .MuiLoadingButton-loadingIndicator {
    position: static;
    left: 0px;
    margin-left: 4px;
  }

  &.MuiLoadingButton-loading {
    color: inherit !important;
  }
}
